import styled from "styled-components";

export const LayoutContainer = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "main"
    "footer";
`;

export const ContentContainer = styled.div`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  grid-area: main;
`;
