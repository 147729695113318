import React from "react";
import { Link } from "react-router-dom";
import { topics } from "../App";
import {
  Arrow,
  BannerNavLink,
  NavBar,
  NavLink,
} from "./SubCategory/index.styles";

export const Navigation = ({ topic, module, selectedCourse }) => {
  const formattedTopic = topic.charAt(0).toUpperCase() + topic.slice(1) || "";

  return (
    <NavBar>
      <Link to="/">
        <NavLink>School</NavLink>
      </Link>
      <Arrow> &gt; </Arrow>
      <Link to={`/subcategory/${formattedTopic}`}>
        <NavLink>{formattedTopic}</NavLink>
      </Link>
      {module && (
        <>
          <Arrow> &gt; </Arrow>
          <NavLink>{module}</NavLink>
        </>
      )}
      {selectedCourse && (
        <>
          <Arrow> &gt; </Arrow>
          <NavLink>{selectedCourse}</NavLink>
        </>
      )}
    </NavBar>
  );
};

export const BannerNavigation = (props) => {
  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        bottom: "12px",
        justifyContent: "space-between",
      }}
    >
      {topics.map((topic) => {
        const isSelectedTopic = props.selectedTopic === topic.topic;
        return (
          <div
            style={{
              textAlign: "center",
              font: "normal normal normal 16px/25px Poppins",
              letterSpacing: "0px",
              color: "#FE97CE !important",
              padding: "0 18.5px",
              width: "200px",
            }}
          >
            <Link
              style={{ color: "#FE97CE !important" }}
              to={`/subcategory/${topic.topic}`}
            >
              <BannerNavLink isSelectedTopic={isSelectedTopic}>
                {topic.title}
              </BannerNavLink>
            </Link>
            {isSelectedTopic && (
              <hr
                style={{
                  margin: "0",
                  padding: "0",
                  width: "166px",
                  position: "relative",
                  bottom: "-12px",
                  borderTop: "4px solid",
                  color: "#FE97CE",
                  opacity: 1,
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
