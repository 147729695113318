import styled from "styled-components";
import { theme } from "../../styles/theme";

export const Container = styled.div`
  // display: grid;
  // grid-template-areas: "left center right";
  display: flex;
  justify-content: space-between;
  // gap: 100px;
  padding: 1.5rem;
  max-height: 5rem;
  align-items: center;
  @media screen and (max-width: 900px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 40px;
  }
`;

export const NavItemContainer = styled.div`
  max-width: 340px;
  min-height: 12px;
  display: flex;
  justify-content: space-between;
  grid-area: center;
  @media screen and (max-width: 900px) {
    max-width: 100%;
    gap: 20px;
    font-size: 15px;
  }
`;

export const Logo = styled.div`
  grid-area: left;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 900px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const ClubLogo = styled.img`
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SocialsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  grid-area: right;
  min-width: 150px;
  // max-width: 300px;
  @media screen and (max-width: 900px) {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    min-width: 30px;
  }
`;

export const Socials = styled.img`
  width: 30px;
  height: 30px;
  padding: 0.2rem;
  transition: 0.3s;
  &:hover {
    filter: opacity(0.5);
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 50px;
  padding: 0.5rem;
  transition: 0.3s;
  &:hover {
    color: ${theme.colours.purple};
  }
`;

export const ATag = styled.a`
  text-decoration: none;
  color: inherit;
  // animation-name: example;
  // animation-duration: 4s;
  // @keyframes example {
  //   0%   {background-color: red;}
  //   25%  {background-color: yellow;}
  //   50%  {background-color: blue;}
  //   100% {background-color: green;}
`;
