import logo from "./logo.svg";
import "./App.css";
import "./index.css";
import SubCategory from "./components/SubCategory";
import SubCategory2 from "./components/SubCategory2";
import ModuleView from "./components/Module/ModuleView";
import Modules from "./components/Modules/";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./components/HomePage";
import { fitnessData } from "./data/Subcategories/fitnessData";
import { businessData } from "./data/Subcategories/businessData";
import { CoursesTileContainer } from "./components/HomePage/index.styles";
import { financeData } from "./data/Subcategories/financeData";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React } from "@web3-react/core";
import { Layout } from "./components/Layout/Layout";
import { relationshipsData } from "./data/Subcategories/relationshipsData";
import { mindsestData } from "./data/Subcategories/mindsetData";
import { meditationData } from "./data/Subcategories/meditationData";
import meditationSplash from "../src/components/HomePage/assets/meditation.png";
import relationshipsSplash from "../src/components/HomePage/assets/relationships.png";
import emotionalMentalSplash from "../src/components/HomePage/assets/emotional&mental.png";
import fitnessSplash from "../src/components/HomePage/assets/fitness.png";
import businessCareer from "../src/components/HomePage/assets/business-career.png";
import moneyFinanceSplash from "../src/components/HomePage/assets/money-finance.png";
import Navbar from "./components/Layout/Navbar";
import Footer from "./components/Layout/Footer";
import { CONTACT_ABI } from "./tokenAbi";
import { Web3Provider } from "@ethersproject/providers";
// import { initializeConnector } from "@web3-react/core";
// import { MetaMask } from "@web3-react/metamask";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/evm-utils";
import { useEffect, useState } from "react";

export const topics = [
  {
    title: "Fitness",
    topic: "fitness",
    data: fitnessData,
    splash: fitnessSplash,
  },
  {
    title: "Meditation",
    topic: "meditation",
    data: meditationData,
    splash: meditationSplash,
  },
  {
    title: "Emotional & Mental",
    topic: "mental-health",
    data: mindsestData,
    splash: emotionalMentalSplash,
  },
  {
    title: "Relationships",
    topic: "relationships",
    data: relationshipsData,
    splash: relationshipsSplash,
  },
  {
    title: "Business & Career",
    topic: "business",
    data: businessData,
    splash: businessCareer,
  },
  {
    title: "Money & Finance",
    topic: "finance",
    data: financeData,
    splash: moneyFinanceSplash,
  },
];

const ProtectedRoute = ({ authenticated, children }) => {
  if (authenticated !== true) {
    console.log("Not authenticated");
    return <Navigate to="/" replace />;
  }

  return children;
};

function App() {
  // const [metaMask, hooks] =
  //   initializeConnector < MetaMask > ((actions) => new MetaMask({ actions }));
  // const CoinbaseWallet = new WalletLinkConnector({
  //   url: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
  //   appName: "Web3-react Demo",
  //   supportedChainIds: [1, 3, 4, 5, 42],
  // });

  // const WalletConnect = new WalletConnectConnector({
  //   rpcUrl: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
  //   bridge: "https://bridge.walletconnect.org",
  //   qrcode: true,
  // });
  const [show, setShow] = useState(false);

  const Injected = new InjectedConnector({
    supportedChainIds: [1],
  });

  const { activate, deactivate, account, onDisconnect, active } =
    useWeb3React();

  const [ownsASister, setOwnsASister] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  const checkForNft = async () => {
    const chain = EvmChain.ETHEREUM;

    const tokenAddress = "0x51de9c70c192d8c44f407a4c817152325b6a4bfc";
    await Moralis.start({
      apiKey: process.env.REACT_APP_MORALIS_KEY,
      // ...and any other configuration
    });
    // if result.tokenAddress.value === nft's address
    const address = account;
    const response = await Moralis.EvmApi.nft.getWalletNFTCollections({
      address,
      chain,
    });
    // 0x51de9c70C192d8c44f407a4c817152325B6A4BFc
    const ownsASister =
      response.data.result.filter((r) => r.token_address === tokenAddress)
        .length > 0;
    setOwnsASister(ownsASister);
    setAuthenticated(ownsASister && active);
  };

  useEffect(() => {
    checkForNft();
  }, [account]);

  useEffect(() => {
    setShow(true);
    return () => setShow(false);
  }, [authenticated]);

  return (
    // <Layout>
    <div style={{ position: "relative", minHeight: "100vh" }}>
      <Navbar
        onDisconnect={() => deactivate(Injected)}
        onConnect={() => activate(Injected)}
        authenticated={authenticated}
      />

      <div style={{ paddingBottom: "300px" }}>
        <ToastContainer>
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            style={{
              maxWidth: "303px",
            }}
          >
            <Toast.Header
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {authenticated
                ? "School Unlocked! Let the learning begin"
                : "Connect your wallet to begin your journey"}
            </Toast.Header>
            <Toast.Body></Toast.Body>
          </Toast>
        </ToastContainer>
        <HashRouter>
          <Routes>
            {/* If the current URL is /about, this route is rendered
        while the rest are ignored */}
            <Route
              path="/"
              element={
                <HomePage
                  onDisconnect={() => deactivate(Injected)}
                  onConnect={() => activate(Injected)}
                />
              }
            ></Route>
            {topics.map((ele) => {
              // const path = ele.data.courses.author.replace(/\s+/g, "-");
              return (
                <>
                  <Route
                    path={`/subcategory/${ele.topic}`}
                    element={
                      <ProtectedRoute authenticated={authenticated}>
                        <SubCategory data={ele.data} topic={ele.topic} />
                      </ProtectedRoute>
                    }
                  ></Route>

                  {ele.data.courses.map((course) => {
                    return Object.values(course.courseList).map((c) => {
                      const courseTitlePath = c.title
                        .replace(/\s+/g, "-")
                        .toLowerCase();
                      return (
                        <>
                          <Route
                            path={`/subcategory/${
                              ele.topic
                            }/${course?.courseName
                              ?.replace(/\s+/g, "-")
                              .toLowerCase()}`}
                            element={
                              <ProtectedRoute authenticated={authenticated}>
                                <SubCategory2
                                  data={course.courseList}
                                  topic={ele.topic}
                                  creator={course.author}
                                  courseName={course.courseName}
                                />
                              </ProtectedRoute>
                            }
                          ></Route>
                          <Route
                            path={`/subcategory/${
                              ele.topic
                            }/${course?.courseName
                              ?.replace(/\s+/g, "-")
                              .toLowerCase()}/${c.title
                              .replace(/\s+/g, "-")
                              .toLowerCase()}/:title`}
                            element={
                              <ProtectedRoute authenticated={authenticated}>
                                <ModuleView
                                  data={c}
                                  course={course}
                                  creator={course.author}
                                  topic={ele.topic}
                                />
                              </ProtectedRoute>
                            }
                          ></Route>
                        </>
                      );
                    });
                  })}
                </>
              );

              // return (
              //   <>
              //     <Route
              //       path={`/subcategory/${ele.topic}`}
              //       element={<SubCategory data={ele.data} />}
              //     ></Route>
              //     <Route
              //       path={`/subcategory/${path}`}
              //       element={<SubCategory data={ele.data} />}
              //     >
              //       {" "}
              //     </Route>
              //     ;
              //   </>
              // );
            })}

            {/* Note how these two routes are ordered. The more specific
        path="/contact/:id" comes before path="/contact" so that
        route will render when viewing an individual contact */}
            {/* <Route path="/contact/:id">
      <Contact />
    </Route>
    <Route path="/contact">
      <AllContacts />
    </Route>

    {/* If none of the previous routes render anything,
        this route acts as a fallback.

        Important: A route with path="/" will *always* match
        the URL because all URLs begin with a /. So that's
        why we put this one last of all */}
            {/* <Route path="/">
      <Home />
    </Route> */}
          </Routes>
        </HashRouter>
      </div>
      <Footer />
      {/* // </Layout> */}
    </div>
  );
}

export default App;
