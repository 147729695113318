import React from "react";

export const Close = (props) => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42px"
      height="42px"
      viewBox="0 0 512 512"
    >
      <path
        id="null"
        fill={fill}
        stroke="null"
        d="M427.314,107.313L404.686 84.687 256 233.373 107.314 84.687 84.686 107.313 233.373 256 84.686 404.687 107.314 427.313 256 278.627 404.686 427.313 427.314 404.687 278.627 256 427.314 107.313z"
      ></path>
    </svg>
  );
};

export const Download = (props) => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        fill={fill}
        d="M10 9.25V4.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v4.75h2.941a.5.5 0 01.372.834l-4.941 5.502a.5.5 0 01-.744 0l-4.941-5.502a.5.5 0 01.372-.834H10zM18.5 18h-13a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z"
        clipRule="evenodd"
      />
    </svg>
  );
};
