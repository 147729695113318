import { toHaveFormValues } from "@testing-library/jest-dom/dist/matchers";
import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import { VideoContainer } from "./index.styles";
import splash from "../../HomePage/assets/meditation.png";
import { handleDuration } from "./utils";
import { useEffect } from "react";

const Video = ({
  src,
  width = "",
  height = "",
  controls = true,
  allCornersRounded = false,
  thumbnail,
  showTimestamp,
  loadVideo,
  playVideo = false,
}) => {
  const [duration, setDuration] = useState();

  const ref = useRef();
  return (
    <VideoContainer
      style={{
        position: "relative",
        height: `${height}px`,
        width: `${width}px`,
      }}
    >
      <ReactPlayer
        ref={ref}
        fullscreen={true}
        className={
          allCornersRounded
            ? "react-player-all-corners-rounded"
            : "react-player"
        }
        autoPlay
        url={src}
        width={"100%"}
        height={"100%"}
        controls={controls}
        previewTabIndex
        playing={playVideo}
        // light={thumbnail}
        onDuration={(rawDuration) => handleDuration(rawDuration, setDuration)}
        config={{
          file: {
            attributes: {
              poster: thumbnail || "",
            },
          },
        }}
      />
      {/* {showTimestamp && (
        <div
          style={{
            position: "absolute",
            bottom: 3,
            right: 3,
            color: "white",
            background: "rgba(0, 0, 0, 0.5)",
            borderRadius: "4px",
            fontSize: "12px",
          }}
        >
          <div>{duration}</div>
        </div>
      )} */}
    </VideoContainer>
  );
};

export default Video;
