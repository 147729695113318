import styled from "styled-components";
import { theme } from "../../styles/theme";
import { fonts } from "../../styles/typography";

export const Container = styled.div`
  display: flex;

  gap: 20px;
`;

export const Heading = styled.div`
  font: ${fonts.h1};
  color: ${theme.colours.purple};
  // padding: 12px;
`;

export const Subheading = styled.div`
  font: ${fonts.h2};
`;

export const Description = styled.p`
  font: ${fonts.p};
  color: ${theme.colours.darkGrey};
`;

export const DownloadButton = styled.button`
  width: 225px;
  height: 48px;
  border-radius: 24px;
  background: white;
  color: ${theme.colours.pink};
  border: 2px solid ${theme.colours.pink};
  font: ${fonts.p};
  cursor: pointer;
  :hover {
    background: rgba(254, 151, 206, 0.2);
    transition: background-color 300ms linear;
  }
`;

export const NextButton = styled.button`
  width: 225px;
  height: 48px;
  border-radius: 24px;
  color: white;
  background: ${theme.colours.pink};
  border: none;
  font: ${fonts.p};
  cursor: pointer;
  :hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
`;

export const VideoContainer = styled.div`
  border-radius: 12px;
`;
