import { sha256 } from "crypto-hash";

export const mindsestData = {
  title: "Emotional & Mental",
  courses: [
    {
      author: "Rosie Leach",
      courseName: "The Journey Back To Yourself",
      courseList: {
        course1: {
          title: "Module 1",
          modules: [
            {
              title: "Module 1.1",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/module_1_video_1_.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/thumbnails/module_1_video_1_.jpg`,
              },
            },
            {
              title: "Module 1.2",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/module_1_video_2.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/thumbnails/module_1_video_2.jpg`,
              },
            },
            {
              title: "Module 1.3",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/module_1_video_3_.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/thumbnails/module_1_video_3_.jpg`,
              },
            },
          ],
        },
        course2: {
          title: "Module 2",
          modules: [
            {
              title: "Module 2.1",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/module_2_video_1.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/thumbnails/module_2_video_1.jpg`,
              },
            },
            {
              title: "Module 2.2",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/module_2_video_2.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/thumbnails/module_2_video_2.jpg`,
              },
            },
            {
              title: "Module 2.3",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/module_2_video_3.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/thumbnails/module_2_video_3.jpg`,
              },
            },
          ],
        },
        course3: {
          title: "Module 3",
          modules: [
            {
              title: "Module 3.1",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/module_3_video_1.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/thumbnails/module_3_video_1.jpg`,
              },
            },
            {
              title: "Module 3.2",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/module_3_video_2.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/thumbnails/module_3_video_2.jpg`,
              },
            },
            {
              title: "Module 3.3",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/module_3_video_3.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/thumbnails/module_3_video_3.jpg`,
              },
            },
            {
              title: "Module 3.4",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/module_3_video_4.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/thumbnails/module_3_video_4.jpg`,
              },
            },
          ],
        },
        course4: {
          title: "Module 4",
          modules: [
            {
              title: "Module 4.1",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/module_4_video_1.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/thumbnails/module_4_video_1.jpg`,
              },
            },
            {
              title: "Module 4.2",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/module_4_video_2.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/thumbnails/module_4_video_2.jpg`,
              },
            },
          ],
        },
        course5: {
          title: "Module 5",
          modules: [
            {
              title: "Module 5.1",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/module_5_video_1.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/thumbnails/module_5_video_1.jpg`,
              },
            },
            {
              title: "Module 5.2",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/module_5_video_2.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/thumbnails/module_5_video_2.jpg`,
              },
            },
          ],
        },
      },
    },
  ],
};
