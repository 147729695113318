import GeorgieEducator from "./assets/GeorgieEducator.png";
import CaseyLolaEducator from "./assets/CaseyLola.png";
import AlyshaEducator from "./assets/Alysha.png";
import ErikaElmutsEducator from "./assets/ErikaElmuts.png";

import LaceyFilipich from "./assets/LaceyFilipich.png";
import Rosie from "./assets/Rosie.png";

export const contributors = [
  {
    Name: "Georgie Hubbard",
    Role: "Mindset Coach",
    Image: `${GeorgieEducator}`,
    Link: "https://instagram.com/georgielhubbard",
  },
  {
    Name: "Alysha Sladden",
    Role: "Yoga Instructor",
    Image: `${AlyshaEducator}`,
    Link: "https://instagram.com/alyshasladdenfitness",
  },
  {
    Name: "Casey Lola",
    Role: "Social Media Business Coach",
    Image: `${CaseyLolaEducator}`,
    Link: "https://instagram.com/casey_iola_",
  },
  {
    Name: "Erika Elmuts",
    Role: "Conscious Parenting Coach",
    Image: `${ErikaElmutsEducator}`,
    Link: "https://youtube.com/c/ErikaElmuts",
  },
  {
    Name: "Lacey Filipich",
    Role: "Money & Finance Expert",
    Image: `${LaceyFilipich}`,
    Link: "https://www.linkedin.com/in/laceyjfilipich/",
  },
  {
    Name: "Rosie Leach",
    Role: "Confidence & Self-Love Coach",
    Image: `${Rosie}`,
    Link: "https://www.instagram.com/selflovecoachingwithrosie/",
  },
];
