import styled from "styled-components";
import { theme } from "../styles/theme";
import { fonts } from "../styles/typography";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1152px;
  display: grid;
  // padding: 87px;
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  // position: absolute;
`;

export const VideoContainer = styled.div`
  max-height: 950px;
  width: 675px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: 2px solid #e6e6e6;
  ::-webkit-scrollbar {
    display: none;
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 15px;
  }
`;

export const Title = styled.div`
  font: normal normal bold 32px/44px Poppins;
  color: ${theme.colours.purple};
`;

export const SubComponentTitle = styled.div`
  font: ${fonts.p};
  color: white;
`;

export const TilesContainer = styled.div`
  margin: 0 auto;
  // padding: 12px;
  max-width: 1152px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 4px;
  row-gap: 22px;
`;

export const NavBar = styled.div`
  height: 23px;
  padding: 32px 0;
`;

export const NavLink = styled.a`
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #000000;
  :active {
    color: purple;
  }
`;

export const ContributorsCard = styled.div`
  // width: 300px;
  // height: 300px;
  display: grid;
  grid-template-columns: auto 1fr;
  // margin: 5rem 1.25rem;
  text-align: left;
  gap: 28px;

  @media screen and (max-width: 670px) {
    margin: 4rem 0;
  }
`;

export const Arrow = styled.span`
  color: ${theme.colours.pink};
`;
