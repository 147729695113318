import React from "react";
import {
  Container,
  NavItemContainer,
  Logo,
  ClubLogo,
  SocialsContainer,
  Socials,
  Item,
  ATag,
} from "./index.styles";

import discord from "./assets/discord.png";
import instagram from "./assets/instagram.png";
import twitter from "./assets/twitter.png";
import brandLogo from "./assets/SC-color.png";

import { navbarItems } from "./utils";

const NavItem = ({ name }) => {
  return <Item>{name}</Item>;
};

const Navbar = ({ onConnect, onDisconnect, authenticated }) => {
  return (
    <Container>
      <Logo>
        <a href="https://www.sisterhoodclub.io/">
          <ClubLogo src={brandLogo} alt="" />
        </a>
      </Logo>
      <div style={{ display: "flex", cursor: "pointer" }}>
        {/* <NavItemContainer>
        {Object.values(navbarItems).map((ele, index) => {
          return (
            <ATag key={ele.name + index} href={ele.route} target="_blank">
              <NavItem name={ele.name}></NavItem>
            </ATag>
          );
        })}
      </NavItemContainer> */}
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            gridArea: "center",
            padding: "0 50px",
          }}
        >
          <div style={{ padding: "0 15px" }}>
            {" "}
            <ATag onClick={() => onConnect()}>
              {authenticated ? "Wallet connected" : "Connect wallet"}
            </ATag>
          </div>
          <div>
            {" "}
            <ATag onClick={() => onDisconnect()}>Logout</ATag>
          </div>
        </div>
        <SocialsContainer>
          <div style={{ display: "flex", gap: "30px" }}>
            <div>
              <a href="https://discord.com/invite/mMvXjYpbjW">
                <Socials src={discord} alt="Discord Logo" />
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/sisterhoodclub_nft/">
                <Socials src={instagram} alt="Instagram Logo" />
              </a>
            </div>
            <div>
              <a href="https://twitter.com/Sisterhoodclub_">
                <Socials src={twitter} alt="Twitter Logo" />
              </a>
            </div>
          </div>
        </SocialsContainer>
      </div>
    </Container>
  );
};

export default Navbar;
