import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { theme } from "../../styles/theme";
import Tile from "../Tile/tile";
import { Container, Border } from "./index.styles";

const ModuleNav = ({
  modules,
  module,
  creator,
  topic,
  course,
  onClick,
  selectedModule,
  moduleName,
  loadVideo,
}) => {
  useEffect(() => {
    const titleElement =
      document.getElementById(moduleName) || document.getElementById("");
    if (titleElement) titleElement.scrollIntoView({ behavior: "smooth" });
  }, [moduleName]);

  return (
    <Border>
      <Container>
        {modules.map((m) => {
          const courseTitlePath = m.title.replace(/\s+/g, "-").toLowerCase();
          const coursePath = course.replace(/\s+/g, "-").toLowerCase();
          return (
            <Link
              to={`/subcategory/${topic.toLowerCase()}/${coursePath}/${module
                .replace(/\s+/g, "-")
                .toLowerCase()}/${courseTitlePath}`}
            >
              {" "}
              <div
                id={courseTitlePath}
                style={{
                  background:
                    selectedModule?.title == m?.title &&
                    "rgba(113,72,212, 0.2)",
                  textAlign: "center",
                  display: "flex",
                  height: "109px",
                  width: "440px",
                  margin: "0 auto",
                  borderRadius: "4px",
                }}
              >
                <div style={{}}>
                  <Tile
                    data={m}
                    onClick={() => onClick(m)}
                    creator={creator}
                    thumbnail={m.files.thumbnail}
                    loadVideo
                  />
                </div>
              </div>
            </Link>
          );
        })}
      </Container>
    </Border>
  );
};

export default ModuleNav;
