import { sha256 } from "crypto-hash";

export const meditationData = {
  title: "Meditation",
  courses: [
    {
      author: "Georgie Hubbard",
      courseName: "After Yoga Meditation",
      courseList: {
        course1: {
          title: "After Yoga Meditation",
          modules: [
            {
              title: "After Yoga Meditation 1",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/after_yoga/after_yoga_meditation_1_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/after_yoga/thumbnails/after_yoga_1_large.png`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "After Yoga Meditation 2",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/after_yoga/after_yoga_meditation_2_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/after_yoga/thumbnails/after_yoga_2_large.png`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "After Yoga Meditation 3",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/after_yoga/after_yoga_meditation_3_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/after_yoga/thumbnails/after_yoga_3_large.png`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "After Yoga Meditation 4",
              hash: sha256("lacey-flippich-budget-4"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/after_yoga/after_yoga_meditation_4_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/after_yoga/thumbnails/after_yoga_4_large.png`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "After Yoga Meditation 5",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/after_yoga/after_yoga_meditation_5_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/after_yoga/thumbnails/after_yoga_5_large.png`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "After Yoga Meditation 6",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/after_yoga/after_yoga_meditation_6_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/after_yoga/thumbnails/after_yoga_6_large.png`,
                captions: "",
                pdf: "",
              },
            },
          ],
        },
      },
    },
    {
      author: "Georgie Hubbard",
      courseName: "Evening Meditation",
      courseList: {
        course1: {
          title: "Evening Meditation",
          modules: [
            {
              title: "De-stress",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/de-stress_georgie.m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/thumbnails/de-stress_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "End Of Day Reflection",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/end_of_day_reflection_meditation_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/thumbnails/end_of_day_reflection__large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Relax And Sleep",
              hash: sha256("lacey-flippich-budget-4"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/relaxation_reset_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/thumbnails/relaxation_reset_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Relaxation Reset",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/relaxation_reset_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/thumbnails/relaxation_reset_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Relaxation Sleep",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/relaxation_sleep_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/thumbnails/relaxation_sleep_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Sleep",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/sleep_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/thumbnails/sleep_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Sleep 2",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/sleep_2_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/thumbnails/sleep_2_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Sleep 3",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/sleep_3_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/thumbnails/sleep_3_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Slow Down Unwind",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/slow_down_unwind_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/evening/thumbnails/slow_down_and_unwind_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
          ],
        },
      },
    },
    {
      author: "Georgie Hubbard",
      courseName: "Inspiring Meditation",
      courseList: {
        course1: {
          title: "Inspiring Meditation",
          modules: [
            {
              title: "Affirmation Meditation",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/inspiring/affirmation_meditation_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/inspiring/thumbnails/affirmation_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Cultivating Joy And Happiness",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/inspiring/cultivating_joy_and_happiness_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/inspiring/thumbnails/cultivating_joy_and_happiness_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Gratitude w/ Bernard",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/inspiring/gratitude_bernard.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/inspiring/thumbnails/gratitude_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Gratitude w/ Georgie",
              hash: sha256("lacey-flippich-budget-4"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/inspiring/gratitude_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/inspiring/thumbnails/gratitude_georgie_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Gratitude Meditation",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/inspiring/gratitude_meditation_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/inspiring/thumbnails/gratitude_meditation.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Joy",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/inspiring/joy_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/inspiring/thumbnails/joy_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Loving Kindness Meditation",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/inspiring/loving_kindness_meditation_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/inspiring/thumbnails/loving-kindness_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
          ],
        },
      },
    },
    {
      author: "Georgie Hubbard",
      courseName: "Manifestation Meditation",
      courseList: {
        course1: {
          title: "Manifestation Meditation",
          modules: [
            {
              title: "Acceptance And Letting Go",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/manifestation/acceptance_and_letting_go_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/manifestation/thumbnails/acceptance_and_letting_go_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Attracting Abundance",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/manifestation/attracting_abundance_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/manifestation/thumbnails/attracting_abundance_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Creating A New Reality",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/manifestation/creating_a_new_reality_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/manifestation/thumbnails/creating_a_new_reality_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Intention Setting Georgie",
              hash: sha256("lacey-flippich-budget-4"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/manifestation/intention_setting_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/manifestation/thumbnails/intention_setting_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Manifestation Meditation",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/manifestation/manifestation_meditation_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/manifestation/thumbnails/manifestation_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Visualisation",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/manifestation/visualisation_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/manifestation/thumbnails/visualisation_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
          ],
        },
      },
    },
    {
      author: "Georgie Hubbard",
      courseName: "Morning Meditation",
      courseList: {
        course1: {
          title: "Morning Meditation",
          modules: [
            {
              title: "Beginner Meditation w/ Bernard",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/morning/beginner_meditation_bernard.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/morning/thumbnails/beginner_meditation_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Energy & Focus",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/morning/energy_focus_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/morning/thumbnails/energy_focus_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Good Mrning Gratitude",
              hash: sha256("lacey-flippich-budget-4"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/morning/good_morning_gratitude_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/morning/thumbnails/good_morning_gratitude_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Morning Affirmation.mp3",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/morning/morning_affirmations_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/morning/thumbnails/morning_affirmation_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Morning Appreciation.mp3",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/morning/morning_appreciation_meditation.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/morning/thumbnails/morning_appreciation_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Morning Focus",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/morning/morning_focus_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/morning/thumbnails/morning_focus_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Morning Intention Setting 2",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/morning/morning_intention_setting_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/morning/thumbnails/morning_intention_setting_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Morning Intention Setting 2",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/morning/morning_intention_setting_2_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/morning/thumbnails/morning_intention_setting_2_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
          ],
        },
      },
    },
    {
      author: "Georgie Hubbard",
      courseName: "Presence Meditation",
      courseList: {
        course1: {
          title: "Presence Meditation",
          modules: [
            {
              title: "Awareness Reset",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/awareness_reset_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/thumbnail/awareness_reset_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Awareness Through Breath",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/awareness_through_breath_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/thumbnail/awareness_through_breath_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Breathing Meditation w/ Kyle",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/breathing_meditation_kyle.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/thumbnail/awareness_through_breath_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Building Awareness Meditation",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/building_awareness_meditation_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/thumbnail/building_awareness_meditation_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Calming And Reset w/ Bernard",
              hash: sha256("lacey-flippich-budget-4"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/calming_and_reset_bernard.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/thumbnail/calming_and_reset_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "De Stress And Relax",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/de-stress_and_relax_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/thumbnail/destress_and_relax_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Growth Through Self Awareness",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/growth_through_self-awareness_bernard.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/thumbnail/growth_through_self-awareness_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Healing Anxiety",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/healing_anxiety_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/thumbnail/healing_anxiety_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Mind And Body Scan w/ Kyle",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/mind_and_body_scan_kyle.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/thumbnail/mind_and_body_scan_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Mindfulness And Awareness",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/mindfulness_and_awareness_georgie.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/thumbnail/mindfulness_and_awareness_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Mindfulness And Presence",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/mindfulness_and_presence.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/thumbnail/mindfulness_and_presence_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Reset And De-stress",
              hash: sha256("lacey-flippich-budget-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/reset_and_de-stress.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/meditations/georgie-hubbard/presence/thumbnail/reset_and_destress_large.jpeg`,
                captions: "",
                pdf: "",
              },
            },
          ],
        },
      },
    },
  ],
};
