import styled from "styled-components";
import { theme } from "../styles/theme";
import { fonts } from "../styles/typography";
import splash from "./assets/splash.png";

export const Container = styled.div`
  font-family: Poppins, serif;

  display: grid;
  gap: 60px;
  // grid-template-rows: 2fr 1fr 1fr;
  padding-bottom: 100px;
  // padding-bottom: 300px; // uncomment for fixed height navbar
`;

export const ComponentContainer = styled.div`
  height: 729px;
  /* UI Properties */
  // background-image: linear-gradient(
  //     242deg,
  //     rgba(254, 151, 206) 0%,
  //     rgba(166, 102, 210, 0.52) 51%,
  //     rgba(113, 72, 212, 0.5) 100%
  //   ),
  //   url(${splash});
  background: transparent
    linear-gradient(242deg, #fe97ce 0%, #a666d2 51%, #7148d4 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  display: grid;
  grid-template-rows: 1fr 0fr 0fr;
  place-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
  position: relative;
`;

export const SplashContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background: transparent url(${splash}) 0% 0% no-repeat padding-box;
  // mix-blend-mode: color;
  opacity: 0.73;
  place-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 700px;
  height: 100%;
  /* UI Properties */
  text-align: center;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  display: grid;
  place-items: center;
  margin: 0 auto;
`;

export const SplashTitle = styled.img`
  width: 50%;
`;

export const CoachesButton = styled.button`
  width: 236px;
  margin: 1rem;
  color: white;
  height: 48px;
  border-radius: 24px;
  border: none;
  background-color: RGBA(254, 151, 206, 1);
  font: normal normal bold 16px/25px Poppins;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  @media screen and (max-width: 900px) {
    width: 200px;
    margin: 1rem 10% 1rem 10%;
  }
`;

export const CoursesButton = styled.button`
  width: 236px;
  margin: 1rem;
  color: white;
  height: 48px;
  border-radius: 24px;
  border: 2px solid #fe97ce;
  color: #fe97ce;
  background-color: RGBA(254, 151, 206, 0);
  font: normal normal bold 16px/25px Poppins;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  @media screen and (max-width: 900px) {
    width: 200px;
    margin: 1rem 10% 1rem 10%;
  }
`;

export const CoachesSection = styled.div`
  max-width: 1048px;
  margin: 0 auto;
  // height: 620px;
  text-align: center;
`;

export const Title = styled.h1`
  // top: 903px;
  // left: 484px;
  width: 472px;
  height: 53px;
  /* UI Properties */
  background: transparent
    linear-gradient(80deg, #7148d4 0%, #a666d2 55%, #fe97ce 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
`;

export const ContributorCardContainer = styled.div`
  font-family: Poppins, serif;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  place-items: center;
  justify-content: center;
  @media screen and (max-width: 670px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3fr 3fr;
    align-items: center;
    justify-content: center;
    gap: 0;
  }
`;

export const ContributorsCard = styled.div`
  // width: 300px;
  // height: 300px;
  margin: 5rem 1.25rem;
  text-align: center;

  @media screen and (max-width: 670px) {
    margin: 4rem 0;
  }
`;

export const TeamRole = styled.div`
  color: ${theme.colours.pink};
  font-weight: bold;
`;

export const TeamBio = styled.p`
  opacity: 0.6;
  font-size: 14px;
`;

export const Image = styled.img`
  width: 168px;
  height: 168px;
  border-radius: 8rem;
`;

export const TeamName = styled.div`
  margin-top: 1rem;
  font-weight: bold;
  color: ${theme.colours.purple};
`;

export const CoursesSection = styled.div`
  margin: 0 auto;
  text-align: center;
  display: grid;
  gap: 68px;
`;

export const CoursesTileContainer = styled.div`
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 1046px;
  gap: 5px;
`;

export const CourseTile = styled.div`
  position: relative;
  color: #ffffff !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  width: 348px;
  height: 204px;
  border-radius: 12px;
  // opacity: 0.54;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CourseTileHover = styled.div`
  position: absolute;
  color: #ffffff !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  width: 348px;
  height: 204px;
  border-radius: 12px;
  transition: opacity 0.3s;
  opacity: 0;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.25 !important;
  }
`;

export const TopicTitle = styled.h4`
  color: #ffffff !important;
  font: ${fonts.h4};
`;
