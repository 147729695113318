import React from "react";
import { BannerComponent } from "../SharedComponents/Banner";
import { businessData } from "../../data/Subcategories/businessData";
import { SubcategoryTile } from "../Module/Tile/tile";
import {
  Arrow,
  Container,
  NavBar,
  NavLink,
  TilesContainer,
} from "./index.styles";
import "./styles.css";
import { fitnessData } from "../../data/Subcategories/fitnessData";
import { CategoryButton } from "../SharedComponents/index.styles";
import { Link } from "react-router-dom";
import { string } from "prop-types";
import { BannerNavigation, Navigation } from "../common";
import { topics } from "../../App";
const SubCategory = ({ data, topic }) => {
  const subComponent = () => {
    const categoryButtonList = [
      "Business & Career",
      "Relationships & Parenting",
      "Money & Finance",
      "Mindset",
      "Fitness",
    ];
    return (
      <div>
        {categoryButtonList.map((ele) => {
          return <CategoryButton>{ele}</CategoryButton>;
        })}
      </div>
    );
  };
  return (
    <div>
      <BannerComponent title={data.title}>
        <BannerNavigation selectedTopic={topic} />
      </BannerComponent>

      <Container>
        <Navigation topic={topic} />
        <TilesContainer>
          {data.courses.map((course) => {
            // const path = data.courses.author.replace(/\s+/g, "-");
            const courseTitlePath = course.courseName
              .replace(/\s+/g, "-")
              .toLowerCase();
            const path = topics.filter((x) => {
              return x.title == data.title;
            })[0];
            return (
              <Link to={`/subcategory/${path?.topic}/${courseTitlePath}`}>
                <SubcategoryTile
                  height={248}
                  width={276}
                  videoHeight={156}
                  videoWidth={276}
                  title={course.courseName}
                  creator={course.author}
                  src={course.courseList.course1.modules[0].files.thumbnail}
                  thumbnail={
                    course.courseList.course1.modules[0].files.thumbnail
                  }
                  controls={false}
                  courseName={course.courseName}
                />
              </Link>
            );
          })}
        </TilesContainer>
        {/* <TilesContainer>
          {data.courses.map((course) => {
            return Object.values(course.courseList).map((cl, i) => {
              return cl.modules.map((courseItem) => {
                // const path = data.courses.author.replace(/\s+/g, "-");
                const courseTitlePath = cl.title
                  .replace(/\s+/g, "-")
                  .toLowerCase();
                const path = topics.filter((x) => {
                  return x.title == data.title;
                })[0];
                return (
                  <Link to={`/subcategory/${path.topic}/${courseTitlePath}`}>
                    <SubcategoryTile
                      height={248}
                      width={276}
                      videoHeight={156}
                      videoWidth={276}
                      title={courseItem.title}
                      creator={course.author}
                      src={courseItem.files.video}
                      thumbnail={courseItem.files.thumbnail}
                      controls={false}
                      courseName={course.courseName}
                    />
                  </Link>
                );
              });
            });
          })}
        </TilesContainer> */}
      </Container>
    </div>
  );
};

export default SubCategory;
