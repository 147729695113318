import styled from "styled-components";
import { theme } from "../../styles/theme";
import { fonts } from "../../styles/typography";

export const Border = styled.div`
  width: 458px;
  height: 499px;
  // margin: 20px;
  border: 1px solid #7148d429;
  border-radius: 10px;
  padding: 5px;
`;

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  //   grid-template-rows: 1fr;
  height: 499px;
  width: 458px;
  // border: 1px solid #707070;
  overflow: scroll;
  border-radius: 10px;
  ::-webkit-scrollbar {
    background: transparent;
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #7148d4;
    width: 8px;
    border-radius: 15px;
  }
`;

export const TileContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 8px 8px 20px 8px;
  gap: 8px;
`;

export const TextContainer = styled.div`
  //   display: flex;
  padding: 7px 0;
`;

export const VideoContainer = styled.div`
  display: grid;
  // width: 588px;
  // height: 466px;
  background: ${theme.colours.lightGrey};
  border-radius: 12px;
  opacity: 1;
  position: relative;
`;

export const HoverVideoContainer = styled.div`
  display: grid;
  // width: 588px;
  // height: 466px;
  background: black;
  transition: opacity 0.3s;
  border-radius: 12px;
  position: absolute;
  opacity: 0;
  z-index: 4;
  &:hover {
    opacity: 0.25 !important;
  }
`;

export const TextContainerVertical = styled.div`
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2%;
`;

export const TextContainerSubcategoryVertical = styled.div`
  box-sizing: border-box;

  // display: grid;
  // grid-template-rows: 1fr 1fr;
  padding: 0 12px 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // align-items: center;
  // margin-bottom: 2%;
`;

export const TileTitle = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  text-align: left;
  font: normal normal medium 22px/33px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font: ${fonts.p};
`;
export const TileSubtitle = styled.div`
  text-align: left;
  font: normal normal medium 16px/25px Poppins;
  letter-spacing: 0px;
  color: #4f525c;
  opacity: 1;
  font: ${fonts.p};
  letter-spacing: 0;
  font-weight: 400;
`;

export const Title = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  text-align: left;
  font: normal normal medium 22px/33px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font: ${fonts.h2};
`;

export const SubcategoryTitle = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  text-align: left;
  font: normal normal medium 22px/33px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font: ${fonts.h2};
  line-height: 1.5;
  /* max-height: 2em; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const Timestamp = styled.div`
  // width: 556px;
  // height: 23px;
  text-align: left;
  font: normal normal medium 16px/25px Poppins;
  letter-spacing: 0px;
  color: #4f525c;
  opacity: 1;
  font: ${fonts.p};
  letter-spacing: 0;
  font-weight: 400;
`;
