import { sha256 } from "crypto-hash";

export const businessData = {
  title: "Business & Career",
  courses: [
    {
      author: "Georgie Hubbard",
      courseName: "Land Your Dream Job",
      courseList: {
        course1: {
          title: "Mindset",
          modules: [
            {
              title: "Mindset Part 1",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/1_mindset/mindset_part_1.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/1_mindset/thumbnails/mindset_part_1.jpg`,
              },
            },
            {
              title: "Mindset Part 2",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/1_mindset/mindset_part_1.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/1_mindset/thumbnails/mindset_part_2.jpg`,
              },
            },
          ],
        },
        course2: {
          title: "Resume Writing Tips",
          modules: [
            {
              title: "Resume Writing Tips",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/2_resume_writing_tips/resume_writing_tips.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/2_resume_writing_tips/thumbnails/resume_writing_tips.jpg`,
              },
            },
          ],
        },
        course3: {
          title: "Standing Out Online",
          modules: [
            {
              title: "Standing Out Online Part 1",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/3_standing_out_online/standing_out_online_part_1.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/3_standing_out_online/thumbnails/standing_out_online_part_1.jpg`,
              },
            },
            {
              title: "Optimising Linkedin Bio",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/3_standing_out_online/part_2_optimising_li_bio.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/3_standing_out_online/thumbnails/part_2_optimising_li_bio.jpg`,
              },
            },
            {
              title: "Standing Out Online Part 3",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/3_standing_out_online/standing_out_online_part_2_(but_3).mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/3_standing_out_online/thumbnails/standing_out_online_part_2_(but_3).jpg`,
              },
            },
          ],
        },
        course4: {
          title: "Finding Your Dream Job",
          modules: [
            {
              title: "Finding Your Dream Job",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/4_finding_your_dream_job_/finding_your_dream_job.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/4_finding_your_dream_job_/thumbnails/finding_your_dream_job.jpg`,
              },
            },
          ],
        },
        course5: {
          title: "Nail The Interview",
          modules: [
            {
              title: "Nail The Interview",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/5_nail_the_interview/nail_the_interview.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/5_nail_the_interview/thumbnails/nail_the_interview.jpg`,
              },
            },
          ],
        },
        course6: {
          title: "Staying Motivated",
          modules: [
            {
              title: "Staying Motivated",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/6_staying_motivated/staying_motivated.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/6_staying_motivated/thumbnails/staying_motivated.jpg`,
              },
            },
          ],
        },
        course7: {
          title: "Bonus",
          modules: [
            {
              title: "Popping Linkedin Profile Picture",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/7_bonus/popping_linkedin_profile_picture.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/georgie-hubbard/dream-job/7_bonus/thumbnails/popping_linkedin_profile_picture.jpg`,
              },
            },
          ],
        },
      },
    },
    {
      author: "Casey Lola",
      courseName: "Instagram For Business Academy",
      courseList: {
        course1: {
          title: "The Fundamentals Of Your Profile",
          modules: [
            {
              title: "The Fundamentals Of Your Profile",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/the_fundamentals_of_your_profile/1_optimizing_bio_1.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/the_fundamentals_of_your_profile/thumbnails/1_optimizing_bio_1.mp4.jpg`,
              },
            },
            {
              title: "Changing Account Type",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/the_fundamentals_of_your_profile/2_changing_account_type.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/the_fundamentals_of_your_profile/thumbnails/2_changing_account_type.mp4.jpg`,
              },
            },
            {
              title: "Spring Clean Feed",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/the_fundamentals_of_your_profile/3_spring_clean_feed.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/the_fundamentals_of_your_profile/thumbnails/3_spring_clean_feed.mp4.jpg`,
              },
            },
          ],
        },
        course2: {
          title: "Content Creation",
          modules: [
            {
              title: "Importance Of Quality Content",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/1_importance_of_quality_content.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/1_importance_of_quality_content.mp4.jpg`,
              },
            },
            {
              title: "Content List Exercise",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/2_content_list_exercise.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/2_content_list_exercise.mp4.jpg`,
              },
            },
            {
              title: "Content Research",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/3_content_framework.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/3_content_framework.mp4.jpg`,
              },
            },
            {
              title: "Finding Viral Content",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/5_finding_viral_content.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/5_finding_viral_content.mp4.jpg`,
              },
            },
            {
              title: "Instagram Post Types",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/6_instagram_post_types.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/6_instagram_post_types.mp4.jpg`,
              },
            },
            {
              title: "Reels Strategy",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/7_reels_strategy.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/7_reels_strategy.mp4.jpg`,
              },
            },
            {
              title: "Carousel Strategy",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/8_carousel_strategy.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/8_carousel_strategy.mp4.jpg`,
              },
            },
            {
              title: "Photos Quotes And Infographics",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/9_photos_quotes_and_infographics.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/9_photos_quotes_and_infographics.mp4.jpg`,
              },
            },
            {
              title: "Lives And Videos",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/10_lives_and_videos.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/10_lives_and_videos.mp4.jpg`,
              },
            },
            {
              title: "Story Strategy",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/11_story_strategy.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/11_story_strategy.mp4.jpg`,
              },
            },
            {
              title: "Planning Your Content Schedule",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/12_planning_your_content_schedule.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/12_planning_your_content_schedule.mp4.jpg`,
              },
            },
            {
              title: "Caption Writing",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/13_caption_writing.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/13_caption_writing.mp4.jpg`,
              },
            },
            {
              title: "Batch Creating",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/14_batch_creating.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/14_batch_creating.mp4.jpg`,
              },
            },
            {
              title: "Content Batch Creation",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/14_content_batch_creation.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/14_content_batch_creation.mp4.jpg`,
              },
            },
            {
              title: "Hashtags",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/15_hashtags.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/15_hashtags.mp4.jpg`,
              },
            },
            {
              title: "30 Day Content Done For You",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/17_30_day_content_done_for_you.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/17_30_day_content_done_for_you.mp4.jpg`,
              },
            },
            {
              title: "18 Content Tips For Mlm",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/18_content_tips_for_mlm.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/18_content_tips_for_mlm.mp4.jpg`,
              },
            },
            {
              title: "Content Planning",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/conten_planning.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/content-creation/thumbnails/conten_planning.mp4.jpg`,
              },
            },
          ],
        },
        course3: {
          title: "Attracting And Growing",
          modules: [
            {
              title: "DM Strategy",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/attracting-and-growing/2_dm_strategy.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/attracting-and-growing/thumbnails/2_dm_strategy.mp4.jpg`,
              },
            },
            {
              title: "Advanced DM Strategy Part 1",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/attracting-and-growing/3_advance_dm_strategy.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/attracting-and-growing/thumbnails/2_dm_strategy.mp4.jpg`,
              },
            },
            {
              title: "Advanced DM Strategy Part 2",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/attracting-and-growing/4_advance_dm_strategy_part_2.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/attracting-and-growing/thumbnails/4_advance_dm_strategy_part_2.mp4.jpg`,
              },
            },
          ],
        },
        course4: {
          title: "Bonus",
          modules: [
            {
              title: "Enrollment Call Part 1",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/bonus/1_enrollment_call_1.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/bonus/thumbnails/1_enrollment_call_1.mp4.jpg`,
              },
            },
            {
              title: "Enrollment Call Part 2",
              hash: sha256("georgie-hubbard-mediation-affirmation"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/bonus/2_enrollment_call_2.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/career/casey-lola/instagram_for_business/bonus/thumbnails/2_enrollment_call_2.mp4.jpg`,
              },
            },
          ],
        },
      },
    },
  ],
};
