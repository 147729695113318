export const handleDuration = (rawDuration, setDuration) => {
  if (!rawDuration) return;
  // rawDuration = rawDuration.toFixed(2);
  let minutes = Math.floor(rawDuration / 60);
  let extraSeconds = rawDuration % 60;

  minutes = minutes < 10 ? "0" + minutes : minutes;
  extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;

  let seconds = minutes + ":" + parseInt(extraSeconds);
  if (seconds.length === 4) {
    seconds = seconds + "0";
  }
  setDuration(seconds);
};
