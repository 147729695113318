import React from "react";
import {
  Container,
  Footerbrand,
  ClubLogo,
  SocialsContainer,
  Socials,
  NavItemContainer,
  NavItem,
  Copyright,
  ATag,
} from "./index.styles.js";
import discord from "./assets/DiscordWhite.png";
import instagram from "./assets/InstagramWhite.png";
import twitter from "./assets/TwitterWhite.png";
import brandLogo from "./assets/SCWhite.png";

const footerLinks = [
  {
    name: "About",
    route: "https://www.sisterhoodclub.io/#About",
  },
  {
    name: "Roadmap",
    route: "https://www.sisterhoodclub.io/#Roadmap",
  },
  {
    name: "Team",
    route: "https://www.sisterhoodclub.io/#Team",
  },
  // {
  //   name: "School",
  //   route: "",
  // },
];

const Footer = () => {
  return (
    <Container>
      <div>
        <Footerbrand>
          <ClubLogo src={brandLogo} />
        </Footerbrand>
        <NavItemContainer>
          {Object.values(footerLinks).map((ele) => {
            // const { name } = ele || {};
            return (
              <ATag key={ele.name} href={ele.route}>
                <NavItem name={ele.name}>{ele.name}</NavItem>
              </ATag>
            );
          })}
        </NavItemContainer>
      </div>
      <SocialsContainer>
        <ATag href="https://discord.com/invite/mMvXjYpbjW">
          <Socials src={discord} alt="Discord Logo" />
        </ATag>

        <ATag href="https://www.instagram.com/sisterhoodclub_nft/">
          <Socials src={instagram} alt="Instagram Logo" />
        </ATag>

        <ATag href="https://twitter.com/Sisterhoodclub_">
          <Socials src={twitter} alt="Twitter Logo" />
        </ATag>
        <Copyright>Copyright &#169; 2022, Sisterhood Club</Copyright>
      </SocialsContainer>
    </Container>
  );
};

export default Footer;
