import { sha256 as hasher } from "crypto-hash";

const sha256 = async (val) => {
  return await hasher(val);
};

export const fitnessData = {
  title: "Fitness",
  courses: [
    {
      author: "Alysha Sladden",
      courseName: "Barre",
      courseList: {
        course1: {
          title: "Barre",
          modules: [
            {
              title: "Barre 1",
              hash: sha256("alysha-sladden-barre-1"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/barre_1.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/thumbnails/barre_1.jpg`,
              },
            },
            {
              title: "Barre 2",
              hash: sha256("alysha-sladden-barre-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/barre_2.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/thumbnails/barre_2.jpg`,
              },
            },
            {
              title: "Barre 3",
              hash: sha256("alysha-sladden-barre-3"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/barre_3.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/thumbnails/barre_3.jpg`,
              },
            },
            {
              title: "Barre 4",
              hash: sha256("alysha-sladden-barre-4"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/barre_4.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/thumbnails/barre_4.jpg`,
              },
            },
            {
              title: "Barre 5",
              hash: sha256("alysha-sladden-barre-5"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/barre_5.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/thumbnails/barre_5.jpg`,
              },
            },
            {
              title: "Barre 6",
              hash: sha256("alysha-sladden-barre-6"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/barre_6.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/thumbnails/barre_6.jpg`,
              },
            },
            {
              title: "Barre 7",
              hash: sha256("alysha-sladden-barre-7"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/barre_7.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/thumbnails/barre_7.jpg`,
              },
            },
            {
              title: "Barre 8",
              hash: sha256("alysha-sladden-barre-8"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/barre_8.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/thumbnails/barre_8.jpg`,
              },
            },
            {
              title: "Barre 9",
              hash: sha256("alysha-sladden-barre-9"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/barre_9.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/thumbnails/barre_9.jpg`,
              },
            },
            {
              title: "Barre 10",
              hash: sha256("alysha-sladden-barre-10"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/barre_10.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/thumbnails/barre_10.jpg`,
              },
            },
            {
              title: "Barre 11",
              hash: sha256("alysha-sladden-barre-11"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/barre_11.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/thumbnails/barre_11.jpg`,
              },
            },
            {
              title: "Barre 12",
              hash: sha256("alysha-sladden-barre-12"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/barre_12.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Barre/thumbnails/barre_12.jpg`,
              },
            },
          ],
        },
      },
    },
    {
      author: "Alysha Sladden",
      courseName: "Yoga",
      courseList: {
        course1: {
          title: "Yoga",
          modules: [
            {
              title: "Yoga 1",
              hash: sha256("alysha-sladden-yoga-1"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_1.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_1.jpg`,
              },
            },
            {
              title: "Yoga 2",
              hash: sha256("alysha-sladden-yoga-2"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_2.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_2.jpg`,
              },
            },
            {
              title: "Yoga 3",
              hash: sha256("alysha-sladden-yoga-3"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_3.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_3.jpg`,
              },
            },
            {
              title: "Yoga 4",
              hash: sha256("alysha-sladden-yoga-4"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_4.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_4.jpg`,
              },
            },
            {
              title: "Yoga 5",
              hash: sha256("alysha-sladden-yoga-5"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_5.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_5.jpg`,
              },
            },
            {
              title: "Yoga 6",
              hash: sha256("alysha-sladden-yoga-6"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_6.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_6.jpg`,
              },
            },
            {
              title: "Yoga 7",
              hash: sha256("alysha-sladden-yoga-7"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_7.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_7.jpg`,
              },
            },
            {
              title: "Yoga 8",
              hash: sha256("alysha-sladden-yoga-8"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_8.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_8.jpg`,
              },
            },
            {
              title: "Yoga 9",
              hash: sha256("alysha-sladden-yoga-9"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_9.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_9.jpg`,
              },
            },
            {
              title: "Yoga 10",
              hash: sha256("alysha-sladden-yoga-10"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_10.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_10.jpg`,
              },
            },
            {
              title: "Yoga 11",
              hash: sha256("alysha-sladden-yoga-11"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_11.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_11.jpg`,
              },
            },
            {
              title: "Yoga 12",
              hash: sha256("alysha-sladden-yoga-12"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_12.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_12.jpg`,
              },
            },
            {
              title: "Yoga 13",
              hash: sha256("alysha-sladden-yoga-13"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_13.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_13.jpg`,
              },
            },
            {
              title: "Yoga 14",
              hash: sha256("alysha-sladden-yoga-14"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_14.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_14.jpg`,
              },
            },
            {
              title: "Yoga 15",
              hash: sha256("alysha-sladden-yoga-15"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_15.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_15.jpg`,
              },
            },
            {
              title: "Yoga 16",
              hash: sha256("alysha-sladden-yoga-16"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_16.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_16.jpg`,
              },
            },
            {
              title: "Yoga 17",
              hash: sha256("alysha-sladden-yoga-17"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_17.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_17.jpg`,
              },
            },
            {
              title: "Yoga 18",
              hash: sha256("alysha-sladden-yoga-18"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_18.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_18.jpg`,
              },
            },
            {
              title: "Yoga 19",
              hash: sha256("alysha-sladden-yoga-19"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_19.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_19.jpg`,
              },
            },
            {
              title: "Yoga 20",
              hash: sha256("alysha-sladden-yoga-20"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_20.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_20.jpg`,
              },
            },
            {
              title: "Yoga 21",
              hash: sha256("alysha-sladden-yoga-21"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_21.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_21.jpg`,
              },
            },
            {
              title: "Yoga 22",
              hash: sha256("alysha-sladden-yoga-22"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_22.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_22.jpg`,
              },
            },
            {
              title: "Yoga 23",
              hash: sha256("alysha-sladden-yoga-23"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_23.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_23.jpg`,
              },
            },
            {
              title: "Yoga 24",
              hash: sha256("alysha-sladden-yoga-24"),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/yoga_24.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/fitness/alysha-sladden/Yoga/thumbnails/yoga_24.jpg`,
              },
            },
          ],
        },
      },
    },
  ],
};
