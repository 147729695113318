import { sha256 } from "crypto-hash";

export const relationshipsData = {
  title: "Relationships",
  courses: [
    {
      author: "Rosie Leach",
      courseName: "How To Stop Hating Your Body",
      downloadables: [
        {
          title: "How To Stop Hating Your Body Worksheet.pdf ",
          url: "https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/how_to_stop_hating_your_body_worksheet.pdf",
        },
      ],
      courseList: {
        course1: {
          title: "Introduction",
          modules: [
            {
              title: "Introduction And What To Expect",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_1_lesson_1_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/1.png`,
              },
            },
            {
              title: "Where Are You Now?",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_1_lesson_2_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/2.png`,
              },
            },
            {
              title: "Setting Goals",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_1_lesson_3_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/3.png`,
              },
            },
          ],
        },
        course2: {
          title: "Understanding Toxic Beauty Standards",
          modules: [
            {
              title: "Understanding Toxic Beauty Standards",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_2_lesson_1_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/4.png`,
              },
            },
            {
              title: "Breaking News Flaws Do Not Exist",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_2_lesson_2_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/5.png`,
              },
            },
            {
              title: "There Is No Such Thing As Beautiful",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_2_lesson_3_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/6.png`,
              },
            },
          ],
        },
        course3: {
          title: "Untying Your Worth From Physical Appearance",
          modules: [
            {
              title: "What Will Be On Your Gravestone?",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_3_lesson_1_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/8.png`,
              },
            },
            {
              title: "Has Changing Your Body Actually Made You Happy",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_3_lesson_2_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/9.png`,
              },
            },
          ],
        },
        course4: {
          title: "Self Compassion",
          modules: [
            {
              title: "Why Body Neutrality Is Just As Important As Body Love",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_4_lesson_1_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/9.png`,
              },
            },
            {
              title: "Become Your Own Bestfriend",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_4_lesson_2_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/10.png`,
              },
            },
            {
              title: "The Power Of Affirmations",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_4_lesson_3_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/11.png`,
              },
            },
          ],
        },
        course5: {
          title: "Getting To Know Your Body",
          modules: [
            {
              title: "The Power Of Familiarity",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_5_lesson_1_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/12.png`,
              },
            },
            {
              title: "Mirror Work",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_5_lesson_2_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/13.png`,
              },
            },
            {
              title: "Exploring Your Body",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_5_lesson_3_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/14.png`,
              },
            },
          ],
        },
        course6: {
          title: "The Social Media Trap",
          modules: [
            {
              title: "Create Your Feed",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_6_lesson_1_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/15.png`,
              },
            },
            {
              title: "Removing Judgement",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_6_lesson_2_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/16.png`,
              },
            },
          ],
        },
        course7: {
          title: "Wear What You Want",
          modules: [
            {
              title: "Wardrobe Clearout",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/relationships/how-to-stop-hating-your-body/module_7_lession_1_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/17.png`,
              },
            },
            {
              title: "Wear What Makes You Feel Good",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/relationships/how-to-stop-hating-your-body/module_7_lesson_2_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/18.png`,
              },
            },
            {
              title: "There Is No Such Thing As Flattering",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/relationships/how-to-stop-hating-your-body/module_7_lesson_3_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/19.png`,
              },
            },
          ],
        },
        course8: {
          title: "Acceptance And Gratitude",
          modules: [
            {
              title: "Body Acceptance Exercise",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/relationships/how-to-stop-hating-your-body/module_8_lesson_1_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/20.png`,
              },
            },
            {
              title: "Gratitude",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/relationships/how-to-stop-hating-your-body/module_8_lesson_2_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/21.png`,
              },
            },
          ],
        },
        course9: {
          title: "Moving Forward",
          modules: [
            {
              title: "Finding Out More",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/relationships/how-to-stop-hating-your-body/module_9_lesson_1_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/22.png`,
              },
            },
            {
              title: "Create A Routine",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/relationships/how-to-stop-hating-your-body/module_9_lesson_2_(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/thumbnails/23.png`,
              },
            },
          ],
        },
        course10: {
          title: "How To Stop Hating Your Body",
          modules: [
            {
              title: "Finale",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/how-to-stop-hating-your-body/module_10__(audio_only).m4a`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/mindset/rosie-leach/the-journey-back-to-yourself/thumbnails/module_1_video_1_.jpg`,
              },
            },
          ],
        },
      },
    },
    {
      author: "Erika Elmuts",
      courseName: "Concious Parenting",
      courseList: {
        course1: {
          title: "Concious Parenting",
          downloadables: [
            {
              title: "Child Vulnerability Assessment",
              url: "https://d7nlbd3k7km5q.cloudfront.net/relationships/erika-elmuts/concious-parenting/child_vulnerability_assessment.pdf",
            },
            {
              title: "Parent Assessment",
              url: "https://d7nlbd3k7km5q.cloudfront.net/relationships/erika-elmuts/concious-parenting/parent_assessment_.pdf",
            },
          ],
          modules: [
            {
              title: "Introduction",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/relationships/erika-elmuts/concious-parenting/ccc_introduction.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/relationships/erika-elmuts/concious-parenting/thumbnails/1.png`,
              },
            },
            {
              title: "Principle 1",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/relationships/erika-elmuts/concious-parenting/ccc_principle_1.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/relationships/erika-elmuts/concious-parenting/thumbnails/2.png`,
              },
            },
            {
              title: "Principle 2: Infants ",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/relationships/erika-elmuts/concious-parenting/ccc_principle_2_infants.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/relationships/erika-elmuts/concious-parenting/thumbnails/3.png`,
              },
            },
            {
              title: "Principle 2: Toddlers - Elementary",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/relationships/erika-elmuts/concious-parenting/ccc_principle_2_toddlers___elementary.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/relationships/erika-elmuts/concious-parenting/thumbnails/4.png`,
              },
            },
            {
              title: "Principle 3",
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/relationships/erika-elmuts/concious-parenting/ccc_principle_3.mp3`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/relationships/erika-elmuts/concious-parenting/thumbnails/5.png`,
              },
            },
          ],
        },
      },
    },
  ],
};
