import React from "react";
import {
  H1,
  P,
  Banner,
  CategoryButton,
  ContentContainer,
} from "./index.styles";

export const BannerComponent = ({ children, title }) => {
  return (
    <div style={{ position: "relative" }}>
      <Banner>
        <ContentContainer>
          <H1>{title}</H1>
          {children}
        </ContentContainer>
      </Banner>
    </div>
  );
};
