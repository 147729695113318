import React, { useEffect, useState } from "react";
import VideoNav from "../VideoNav/index";
import Video from "./video";
import "bootstrap/dist/css/bootstrap.min.css";
import { Layout } from "../../Layout/Layout";
import {
  Container,
  Heading,
  Subheading,
  Description,
  DownloadButton,
  NextButton,
} from "./index.styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Close, Download } from "../../styles/icons";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Card, ListGroup } from "react-bootstrap";
import { ATag } from "../../Layout/Footer/index.styles";
import { theme } from "../../styles/theme";
import Footer from "../../Layout/Footer";

const ModuleView = ({ data, creator, topic, course }) => {
  const [module, setModule] = useState();
  const [index, setIndex] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var final = location.pathname.substr(location.pathname.lastIndexOf("/") + 1);

  useEffect(() => {
    Object.values(data.modules).map((x, i) => {
      if (
        x.title
          .replace(/\s+/g, "-")
          .replace(/\?/g, "")
          .replace(/\>-/g, "")
          .toLowerCase() === final.replace(/%3E-/g, "")
      ) {
        setModule(x);
        setIndex(i);
      }
    });
  }, [data, final]);

  const onNext = (goToModule) => {
    const coursePath = data?.title.replace(/\s+/g, "-").toLowerCase();
    const courseTitlePath = data.modules[index + 1]?.title
      .replace(/\s+/g, "-")
      .toLowerCase();
    navigate(
      `/subcategory/${topic.toLowerCase()}/${coursePath}/${courseTitlePath}`
    );
  };

  let url = location.pathname.split("/").slice(2, 4).join("/");
  const onClose = () => {
    navigate(`/subcategory/${url}`);
    // const coursePath = data?.title.replace(/\s+/g, "-").toLowerCase();

    navigate(-1);
  };

  const downloadables = () => {
    return (
      <Card style={{ width: "100%" }}>
        <ListGroup variant="flush">
          {course &&
            course.downloadables?.map((d) => {
              return (
                <ListGroup.Item>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <ATag href={d.url} downloATagd target={"_blank"}>
                      {d.title}
                    </ATag>
                    <ATag href={d.url} download target={"_blank"}>
                      <Download fill={theme.colours.darkGrey} />
                    </ATag>
                  </div>
                </ListGroup.Item>
              );
            })}
        </ListGroup>
      </Card>
    );
  };

  return (
    <div style={{ margin: "0 auto", padding: "42px", maxWidth: "1440px" }}>
      <div
        style={{
          display: "flex",
          padding: "12px 0",
          justifyContent: "space-between",
        }}
      >
        <Heading style={{ font: "normal normal bold 32px/44px Poppins" }}>
          {course.courseName}
        </Heading>
        <button
          style={{
            background: "none",
            color: "inherit",
            border: "none",
            padding: "0",
            font: "inherit",
            outline: "inherit",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          <Link to={`/subcategory/${url}`}>
            {" "}
            <Close fill="#FE97CE" />
          </Link>
        </button>
      </div>
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "40px",
            width: "882px",
          }}
        >
          <Video
            width={"882"}
            height={"499"}
            src={module?.files.video}
            allCornersRounded
            thumbnail={module?.files.thumbnail}
            loadVideo
            playVideo={true}
          />
          <div>
            <Subheading>{module?.title}</Subheading>
            <Description>{module?.copy}</Description>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            position: "relative",
          }}
        >
          <VideoNav
            modules={data.modules}
            module={data.title}
            onClick={onNext}
            creator={creator}
            topic={topic}
            course={course.courseName}
            selectedModule={module}
            moduleName={final}
            loadVideo
          />
          <div
            style={{ display: "flex", gap: "8px", justifyContent: "flex-end" }}
          >
            {course.downloadables && (
              <>
                {" "}
                <DownloadButton onClick={handleShow}>Downloads</DownloadButton>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Downloadables</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{downloadables()}</Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            )}
            {/* <NextButton onClick={onNext}>Next Category</NextButton> */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ModuleView;
