import styled from "styled-components";
import { theme } from "../../styles/theme";

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
  background: ${theme.colours.purple};
  text-align: center;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  @media screen and (max-width: 900px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 5rem;
  }
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
`;

export const Footerbrand = styled.h1`
  font-size: 20px;
  @media screen and (max-width: 900px) {
    padding: 0;
`;

export const ClubLogo = styled.img`
  padding-bottom: 1.2rem;
  padding-top: 1rem;
  width: 220px;
  @media screen and (max-width: 900px) {
    padding-top: 0;
    width: 160px;
  }
`;

export const SocialsContainer = styled.div`
padding-top: 1rem;
padding-left: 1rem;
@media screen and (max-width: 900px) {
  padding: 0;

  margin: -24px 23px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
}
`;

export const Socials = styled.img`
  padding: 1.5rem;
  width: 30px;
  height: 30px;
  transition: 0.3s;
  &:hover {
    filter: opacity(0.5) 
  }
  @media screen and (max-width: 900px) {
    padding: 0;
    margin-top: 1.5rem;
`;

export const NavItemContainer = styled.div`
  max-width: 100%;
  min-height: 23px;
  display: flex;
  gap: 40px;
  justify-content: space-around;
  padding: 0.5rem 30% 0.5rem;
  @media screen and (max-width: 900px) {
    padding: 0;
  }
`;

export const NavItem = styled.div`
  font-size: 14px;
  transition: 0.3s;
  &:hover {
    color: ${theme.colours.pink};
  }
  @media screen and (max-width: 900px) {
    font-size: 16px;
  }
`;

export const Copyright = styled.div`
  padding-top: 5rem;
  font-size: 14px;
  @media screen and (max-width: 900px) {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    font-size: 10px;
    padding-top: 0;
  }
`;

export const ATag = styled.a`
  text-decoration: none;
  color: inherit;
  &:hover {
    ${theme.colours.purple}
  }
`;
