import { sha256 } from "crypto-hash";

export const financeData = {
  title: "Money & Finance",
  courses: [
    {
      author: "Lacey Filipich",
      courseName: "Introduction",
      courseList: {
        course1: {
          title: "Introduction",
          modules: [
            {
              title: "Introduction",
              hash: sha256("lacey-flippich-intro"),

              copy: (
                <>
                  How do you know how much you can afford to spend? Simple: you
                  create a spending plan (otherwise known as a budget). A
                  spending plan is a tool to help you map out how much money is
                  coming in and out of your bank account, and when, so you can
                  sleep well knowing you’ve got your expenses covered.
                  <br />
                  In this course, we’ll cover: <br />
                  <ul>
                    <li>How a spending plan works,</li>{" "}
                    <li>Why you might want one,</li>
                    <li>How to build your own and make sure it balances, </li>
                    <li>
                      and How to track what you actually spend to make sure your
                      plan is working.
                    </li>
                  </ul>
                  First, some general information about how the course is laid
                  out: <br />
                  <ul>
                    <li>
                      In the videos, on the whiteboard to the left of the
                      screen, you’ll see words appear occasionally. If you find
                      any of these words unfamiliar and the explanation Lacey
                      gives didn’t work for you, you can pause the video and
                      head to the Glossary to read what it means.
                    </li>
                    <li>
                      {" "}
                      You can watch each video with captions, or read the
                      transcript instead of watching if you prefer. Each video
                      has a .pdf transcript linked below.
                    </li>
                    <li>
                      {" "}
                      In the ‘What’s Next?’ section you’ll find links you can
                      explore if you want to learn more about spending plans in
                      general.
                    </li>
                  </ul>
                  <br />
                  Lacey Filipich, founder and director of Money School, is your
                  host for this course. <br />
                  Lacey extends a special thank-you to the businesses that
                  agreed to be part of our videos as examples:
                  <a href="https://vividhairlounge.com.au/">
                    Vivid Hair Lounge
                  </a>
                  and
                  <a href="https://www.georgeandivy.com.au/"> George & Ivy.</a>
                </>
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/video_0_introduction.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/thumbnails/video_0_introduction.png`,
              },
            },
          ],
        },
      },
    },
    {
      author: "Lacey Filipich",
      courseName: "Budgeting",
      downloadables: [
        {
          title: "spending_plan_template_simple_plan_2xa4_pages",
          url: "https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/downloadables/spending_plan_template_simple_plan_2xa4_pages.pdf",
        },
        {
          title: "spending_plan_list_living_at_home",
          url: "https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/downloadables/spending_plan_list_living_at_home.pdf",
        },
        {
          title: "spending_plan_list_moving_out_of_home_",
          url: "https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/downloadables/spending_plan_list_moving_out_of_home_.pdf",
        },
        {
          title: "spending_plan_template_detailed",
          url: "https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/downloadables/spending_plan_template_detailed.pdf",
        },
        {
          title: "spending_plan_template_simple_plan_1xa3_page",
          url: "https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/downloadables/spending_plan_template_simple_plan_1xa3_page.pdf",
        },
      ],
      courseList: {
        course1: {
          title: "Module 1. About Budgeting",
          modules: [
            {
              title:
                "Lesson 1.1 What is a spending plan, and why might you want one?",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              copy: (
                <>
                  Want to know how a spending plan is structured and the three
                  ways it can help you?
                  <br />
                  Lacey explains in the following video:
                  <br />
                  So, what do you include in your plan? Find out in the next
                  lesson…
                </>
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/video_1.1_what_is_a_spending_plan_and_why_might_you_want_one.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/thumbnails/video_1.1_what_is_a_spending_plan_and_why_might_you_want_one.png`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Lesson 1.2 What goes into your spending plan?",
              hash: sha256("lacey-flippich-budget-2"),
              copy: (
                <>
                  What items should you list in each category, and what other
                  information do you need to create your plan?
                  <br />
                  Lacey covers the nitty gritty in this video:
                  <br />
                  You may also like to check out this one-page downloadable
                  <a href="https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/downloadables/spending_plan_list_living_at_home.pdf">
                    {" "}
                    Spending Plan List Living At Home
                  </a>
                  , listing common items people include in their spending plans.
                  <br />
                  How do you work out if your plan is viable? Find out in the
                  next lesson. But first, a quick test to see how much you’ve
                  picked up from the first two lessons…
                </>
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/video_1.2_what_goes_into_your_spending_plan.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/thumbnails/video_1.2_what_goes_into_your_spending_plan.png`,
                captions: "",
                pdf: "",
              },
            },
          ],
        },
        course2: {
          title: "Module 2. Creating your first spending plan",
          modules: [
            {
              title:
                "Lesson 2.1 Filling in the numbers: how much will you earn and spend?",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              copy: (
                <>
                  There are two steps to filling out your numbers:
                  <ol>
                    <li>
                      List all your income sources, along with how much you’ll
                      earn and when the money comes in.
                    </li>
                    <li>
                      List all your spending items, along with how much you’ll
                      spend and how often.{" "}
                    </li>
                  </ol>
                  <br />
                  Lacey outlines how in the following video:
                  <br />
                  So, will your plan work? The next step is to work out the
                  totals. Lacey runs through how in the next lesson…
                </>
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/video_2.1_filling_in_the_numbers_how_much_will_you_earn_and_spend.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/thumbnails/video_2.1_filling_in_the_numbers_how_much_will_you_earn_and_spend.png`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Lesson 2.2 Balancing: is in > out?",
              hash: sha256("lacey-flippich-budget-2"),
              copy: (
                <>
                  For the spending plan to work in real life, the money coming
                  in has to be more than the money going out - i.e. in > out -
                  and not just for the year as a whole. ‘In’ has to be more than
                  ‘out’ each month too, otherwise we might end up in debt.
                  <br />
                  Find out how to work out if your spending plan balances in
                  this video:
                  <br />
                  Wondering what might go into your spending plan? Check out the
                  list of common items to help you work out what to include in
                  yours.
                  <br />
                </>
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/video_2.2_balance_your_plan_is_in_greater_than_out.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/thumbnails/video_2.2_balance_your_plan_is_in_greater_than_out.png`,
                captions: "",
                pdf: "",
              },
            },
          ],
        },
        course3: {
          title: "Module 3. Automating and tracking your spending plan",
          copy: (
            <>
              A spending plan that sits in a drawer or an unused app in your
              phone isn’t helping you.
              <br />
              Setting up systems to make your spending plan happen automatically
              and to check you’re on track are important. We’ll cover those in
              the next two lessons, then there’s a final detailed spending plan
              example for those who’d like to see what it costs to move out of
              home.
              <br />
              Up first, Lacey covers automation…
            </>
          ),
          modules: [
            {
              title: "Lesson 3.1 Lock it in! It's time to automate",
              hash: sha256(
                "Lesson 1.1 What is a spending plan, and why might you want one?"
                  .replace(/\s+/g, "-")
                  .toLowerCase()
              ),
              copy: (
                <>
                  Automating means using tools such as direct debit, online bank
                  transfers and calendar reminders to mimic your spending plan.
                  <br />
                  Lacey covers how they work and the one automation you could
                  consider compulsory in this video:
                  <br />
                  So, how do you know if you’re on track with your spending plan
                  throughout the year? Lacey explains in the next lesson…
                </>
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/video_3.1_lock_it_in_its_time_to_automate.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/thumbnails/video_3.1_lock_it_in_its_time_to_automate.png`,
                captions: "",
                pdf: "",
              },
            },
            {
              title: "Lesson 3.2 Tracking And Improving Your Plan",
              hash: sha256("lacey-flippich-budget-2"),
              copy: (
                <>
                  Moving out of home for the first time is full of surprises,
                  but you don’t want running out of money to be one of them!
                  <br />
                  In this example, Lacey creates a sample spending plan for
                  someone living out of home in a share house:
                </>
              ),
              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/video_3.2_tracking_and_improving_your_plan.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/budgeting/thumbnails/video_3.2_tracking_and_improving_your_plan.png`,
                captions: "",
                pdf: "",
              },
            },
          ],
        },
      },
    },

    {
      author: "Lacey Filipich",
      courseName: "Buy Now Pay Later",
      courseList: {
        course1: {
          title: "Introduction",
          modules: [
            {
              title: "About BNPL",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/bnpl/video_0_introduction.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/bnpl/thumbnails/video_0_introduction.png`,
              },
            },
          ],
        },
        course2: {
          title: "Module 1. About BNPL",
          modules: [
            {
              title: "Lesson 1.1 How borrowing works",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/bnpl/video_1.1_borrowing.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/bnpl/thumbnails/video_1.1_borrowing.png`,
              },
            },
            {
              title: "Lesson 1.2 How BNPL works",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/bnpl/video_1.2_how_does_bnpl_work.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/bnpl/thumbnails/video_1.2_how_does_bnpl_work.png`,
              },
            },
            {
              title: "Lesson 1.3 The good, the bad and the ugly of BNPL",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/bnpl/video_1.3_the_good,_the_bad_and_the_ugly_of_bnpl.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/bnpl/thumbnails/video_1.3_the_good,_the_bad_and_the_ugly_of_bnpl.png`,
              },
            },
          ],
        },
        course3: {
          title: "Module 2. Making BNPL work for you",
          modules: [
            {
              title: "Three steps to paying later without paying a LOT more",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/bnpl/video_2.1_three_steps.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/bnpl/thumbnails/video_2.1_three_steps.png`,
              },
            },
            {
              title: "Lesson 2.2 The bottom line: how much BNPL costs",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/bnpl/video_2.2_the_bottom_line.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/bnpl/thumbnails/video_2.2_the_bottom_line.png`,
              },
            },
          ],
        },
      },
    },
    {
      author: "Lacey Filipich",
      courseName: "Investing",
      courseList: {
        course1: {
          title: "Introduction",
          copy: (
            <>
              Once you’re managing your money well, you can start saving. After
              you’ve filled your buffer fund, you can put any extra savings to
              work for you by investing in assets. If those terms are
              unfamiliar, don’t worry! That’s what we’ll be covering in detail
              in these lessons.
              <br />
              Lacey will explain: <br />
              <ul>
                <li>What compounding means,</li>{" "}
                <li>How investing works and how it’s measured,</li>
                <li>
                  What types of investment options you can choose - including
                  cryptocurrencies, and{" "}
                </li>
                <li>Common investment strategies like ethical investing. </li>
              </ul>
              First, some general information about how the course is laid out:{" "}
              <br />
              <ul>
                <li>
                  In the videos, on the whiteboard to the left of the screen,
                  you’ll see words appear occasionally. If you find any of these
                  words unfamiliar and the explanation Lacey gives didn’t work
                  for you, you can pause the video and head to the Glossary to
                  read what it means.
                </li>
                <li>
                  {" "}
                  You can watch each video with captions, or read the transcript
                  instead of watching if you prefer. Each video has a .pdf
                  transcript linked below.
                </li>
                <li>
                  {" "}
                  IIn the ‘What’s Next?’ section you’ll find links you can
                  explore if you want to learn more about BNPL and borrowing in
                  general.
                </li>
              </ul>
              <br />
              Lacey Filipich, founder and director of Money School, is your host
              for this course. <br />
              Lacey extends a special thank-you to the businesses that agreed to
              be part of our videos as examples:
              <a href="https://vividhairlounge.com.au/"> Vivid Hair Lounge</a>
              and
              <a href="https://www.georgeandivy.com.au/"> George & Ivy.</a>
            </>
          ),
          modules: [
            {
              title: "Introduction",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/investing/video_0_intro.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/investing/thumbnails/video_0_intro.png`,
              },
            },
          ],
        },
        course2: {
          title: "Module 1. About Investing",
          modules: [
            {
              title: "Lesson 1.1 Compounding: the eighth wonder of the world!",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/investing/video_1.1_compounding_the_eighth_wonder_of_the_world.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/investing/thumbnails/compounding_1.1.png`,
              },
            },
            {
              title: "Lesson 1.2 What is investing, and why should you care?",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/investing/video_1.2_what_is_investing_and_why_should_you_care.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/investing/thumbnails/what_is_investing_1.2.png`,
              },
            },
          ],
        },
        course3: {
          title: "Module 2. Investment types and strategies",
          modules: [
            {
              title: "Lesson 2.1 Investing in shares",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/investing/video_2.1_investing_in_shares.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/investing/thumbnails/shares_2.1.png`,
              },
            },
            {
              title: "Lesson 2.2 Investing in property",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/investing/video_2.2_investing_in_property.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/investing/thumbnails/property_2.2.png`,
              },
            },
            {
              title:
                "Lesson 2.3 What is cryptocurrency and is it an investment?",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/investing/video_2.3_what_is_cryptocurrency_and_is_it_an_investment.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/investing/thumbnails/crypto_2.3.png`,
              },
            },
            {
              title: "Lesson 2.4 How do you invest ethically?",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/investing/video_2.4_how_do_you_invest_ethically.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/investing/thumbnails/ethical_2.4.png`,
              },
            },
          ],
        },
      },
    },
    {
      author: "Lacey Filipich",
      courseName: "Shares 101",
      courseList: {
        course1: {
          title: "Shares 101",
          modules: [
            {
              title: "Introduction",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/lesson_0_course_introduction.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/thumbnails/1.png`,
              },
            },
            {
              title: "Lesson 1. Trading Platforms and Placing a Buy Order",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/lesson_1_trading_platforms_and_placing_a_buy_order.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/thumbnails/2.png`,
              },
            },
            {
              title: "Lesson 2. Why Shares",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/lesson_2_why_shares.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/thumbnails/3.png`,
              },
            },
            {
              title: "Lesson 3. Strategy - Long Term or Short Term",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/lesson_3_strategy_long_term_or_short_term.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/thumbnails/4.png`,
              },
            },
            {
              title: "Lesson 4. Common Advice 1 - Buy Blue Chip Shares",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/lesson_4_common_advice_1_buy_blue_chip_shares.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/thumbnails/5.png`,
              },
            },
            {
              title: "Lesson 5. Common Advice 2 - Diversify",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/lesson_5_common_advice_2_diversify.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/thumbnails/6.png`,
              },
            },
            {
              title: "Lesson 6. Common Advice 3 - Index Funds",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/lesson_6_common_advice_3_index_funds.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/thumbnails/7.png`,
              },
            },
            {
              title: "Lesson 7. Common Advice 4 - Ethical Investing",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/lesson_7_common_advice_4_ethical_investing.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/thumbnails/8.png`,
              },
            },
            {
              title: "Lesson 8. Next Steps",
              hash: sha256("lacey-flippich-intro"),

              files: {
                video: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/lesson_8_next_steps.mp4`,
                thumbnail: `https://d7nlbd3k7km5q.cloudfront.net/finance/lacey-filipich/shares/thumbnails/9.png`,
              },
            },
          ],
        },
      },
    },
  ],
};
