import styled from "styled-components";
import { theme } from "../styles/theme";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1152px;
  display: grid;
  padding-bottom: 80px;
`;

export const TilesContainer = styled.div`
  margin: 0 auto;
  // padding: 12px;
  max-height: 770px;
  max-width: 1152px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 4px;
  row-gap: 22px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const NavBar = styled.div`
  height: 23px;
  padding: 32px 0;
`;

export const NavLink = styled.a`
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #000000;
  :active {
    color: purple;
  }
`;

export const BannerNavLink = styled.a`
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #ffffff !important;
  ${(props) =>
    props.isSelectedTopic === true
      ? `color: #FE97CE !important;`
      : `color: #ffffff !important;`}
  :active {
    color: purple;
  }
`;

export const Arrow = styled.span`
  color: ${theme.colours.pink};
  font-weight: 700;
  font: normal normal normal 16px/25px Poppins;
`;
