import React from "react";
import { fonts } from "../../styles/typography";
import Video from "../ModuleView/video";
import {
  TileContainer,
  TextContainer,
  VideoContainer,
  Title,
  Timestamp,
  TextContainerVertical,
  TextContainerSubcategoryVertical,
  SubcategoryTitle,
  TileSubtitle,
  HoverVideoContainer,
} from "../VideoNav/index.styles";

const Tile = ({ data, onClick, creator, courseName, thumbnail, loadVideo }) => {
  return (
    <TileContainer onClick={() => onClick(data.files.video)}>
      <Video
        showTimestamp
        allCornersRounded
        width={160}
        height={90}
        src={data.files.video}
        controls={false}
        allCornersRoundd
        thumbnail={thumbnail}
        loadVideo={loadVideo}
      />
      <TextContainer>
        <SubcategoryTitle
          style={{ height: "54px", font: fonts.p, lineHeight: "1.5em" }}
        >
          {data.title}
          <br />
          {courseName || ""}
        </SubcategoryTitle>
        <TileSubtitle>{creator}</TileSubtitle>
      </TextContainer>
    </TileContainer>
  );
};

export const TileVertical = ({
  title,
  creator,
  height,
  width,
  videoHeight,
  videoWidth,
  src,
  thumbnail,
  showTimestamp,
}) => {
  return (
    <VideoContainer style={{ height: `${height}px`, width: `${width}px` }}>
      <Video
        thumbnail={thumbnail}
        width={videoWidth}
        height={videoHeight}
        src={src}
        showTimestamp={showTimestamp}
      />
      <TextContainerVertical style={{ width: `${width}px` }}>
        <Title style={{ padding: "0 16px" }}>{title}</Title>
        <Timestamp>{creator}</Timestamp>
      </TextContainerVertical>
    </VideoContainer>
  );
};

export const SubcategoryTile = ({
  title,
  creator,
  height,
  width,
  videoHeight,
  videoWidth,
  src,
  thumbnail,
  controls,
  courseName,
}) => {
  return (
    <VideoContainer style={{ height: `${height}px`, width: `${width}px` }}>
      <HoverVideoContainer
        style={{ height: `${height}px`, width: `${width}px` }}
      />
      <Video
        width={videoWidth}
        height={videoHeight}
        src={src}
        thumbnail={thumbnail}
        controls={controls}
      />
      <TextContainerSubcategoryVertical style={{ width: `${width}px` }}>
        <SubcategoryTitle style={{ font: fonts.p }}>
          {/* {title} */}
          <br /> {courseName || ""}
        </SubcategoryTitle>
        <Timestamp>{creator}</Timestamp>
      </TextContainerSubcategoryVertical>
    </VideoContainer>
  );
};

export default Tile;
