import React from "react";
import {
  ComponentContainer,
  ContentContainer,
  SplashTitle,
  CoachesButton,
  CoursesButton,
  CoachesSection,
  ContributorCardContainer,
  ContributorsCard,
  TeamName,
  TeamRole,
  Image,
  TeamBio,
  Container,
  CoursesSection,
  CourseTile,
  TopicTitle,
  CoursesTileContainer,
  SplashContainer,
  CourseTileHover,
} from "./index.styles";

import BrandLogo from "./assets/sh-school-white.png";
import { Heading } from "../SharedComponents/index.styles";
import { contributors as contributorsData } from "./utils";
import { Link } from "react-router-dom";
import { topics } from "../../App";

export const Educators = ({ contributors }) => {
  contributors = contributors || {};

  return (
    <ContributorCardContainer>
      {Object.values(contributors).map((ele, index) => {
        if (!ele) return;
        return (
          <ContributorsCard key={`${ele.Name}-${ele.Role}-${index + 1}-index`}>
            <Image src={ele.Image} alt="" />
            <TeamName>{ele.Name}</TeamName>
            <TeamRole>{ele.Role}</TeamRole>
            <a target="_blank" rel="noreferrer" href={ele.Link}></a>

            <TeamBio>{ele.Bio}</TeamBio>
          </ContributorsCard>
        );
      })}
    </ContributorCardContainer>
  );
};

const HomePage = ({ onClick }) => {
  const topic = [{ title: "" }];
  return (
    <Container>
      <ComponentContainer id="homepage-splash">
        <SplashContainer></SplashContainer>
        <ContentContainer>
          <SplashTitle src={BrandLogo} />
          {/* <button onClick={() => onClick()}>Test connect metamask</button> */}
          {/* <div>
            <CoachesButton>Our Coaches</CoachesButton>
            <CoursesButton>View Courses</CoursesButton>
          </div> */}
        </ContentContainer>
      </ComponentContainer>
      <CoachesSection>
        <Heading>The Coaches</Heading>
        <Educators contributors={contributorsData} />
      </CoachesSection>
      <CourseSection topics={topics} />
    </Container>
  );
};

const CourseSection = ({ topics }) => {
  return (
    <CoursesSection>
      <Heading>Courses</Heading>
      <CoursesTileContainer>
        {topics.map((topic, i) => {
          return (
            <Link to={`/subcategory/${topic.topic}`}>
              <CourseTile
                id={"course-section"}
                splash={topic.splash}
                style={{
                  background: `linear-gradient( rgba(0,0,0,0.6), rgba(0,0,0,.64) ), url(${topic.splash})`,
                }}
              >
                <CourseTileHover style={{}}></CourseTileHover>
                <TopicTitle>{topic.title}</TopicTitle>
              </CourseTile>
            </Link>
          );
        })}
      </CoursesTileContainer>
    </CoursesSection>
  );
};

export default HomePage;
