import styled from "styled-components";
import { theme } from "../styles/theme";
import { fonts } from "../styles/typography";

export const Banner = styled.div`
  width: 100%;
  height: 237px;
  background: transparent
    linear-gradient(260deg, #fe97ce 0%, #a666d2 51%, #7148d4 100%) 0% 0%
    no-repeat padding-box;
  text-align: center;
  color: #ffffff;
  opacity: 1;
`;

export const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const H1 = styled.div`
  font: ${fonts.h1};
  letter-spacing: 0px;
`;

export const H2 = styled.div`
  font: ${fonts.h2};
  letter-spacing: 0px;
  color: ${theme.colours.purple};
`;

export const H4 = styled.div`
  font: ${fonts.h4};
  letter-spacing: 0px;
  color: ${theme.colours.pink};
`;

export const P = styled.div`
  font: ${fonts.p};
  letter-spacing: 0px;
  opacity: 0.72;
`;

export const CategoryButton = styled.a``;

export const Heading = styled.div`
  font-size: 68px;
  font-weight: 800;
  /* top: 903px; */
  /* left: 484px; */
  width: 472px;
  margin: 0 auto;
  text-align: center;
  /* UI Properties */
  background: transparent
    linear-gradient(80deg, #7148d4 0%, #a666d2 55%, #fe97ce 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  background: transparent
    linear-gradient(80deg, #7148d4 0%, #a666d2 55%, #fe97ce 100%) 0% 0%
    no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
